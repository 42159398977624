const formatUrl  = (url) => {
  if (!url || url.trim() === "") {
    return null; 
  }

  // Ensure the URL has a protocol
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }

  return url;
};

export default formatUrl;



